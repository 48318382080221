import {ContactForm} from '@shared/shared-module/models/contact-form-data.model';

export class UpdateContactFormGroup {
  static readonly type = '[ContactForm] Update Contact FormGroup';

  constructor(public payload: ContactForm) {} // Use appropriate type here, or FormGroup
}

export class ClearContactForm {
  static readonly type = '[ContactForm] Clear';
}
